import { RightLogo, WrongLogo } from "assets";
import { Images } from "assets/Images";
import { Button } from "components/Atoms/Button/Button";
import { Textfield } from "components/Atoms/Textfield/Textfield";
import { Typography } from "components/Atoms/Typography/Typography";
import { useFormik } from "formik";
import useSignupAuth from "hooks/auth/useSignup";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GET_EMAIL_VALIDATION, GET_USER_VALIDATION } from "services/apiService";
import * as Yup from "yup";
import { debounce, serialize } from "constants/utils";
import { useCustomError } from "hooks/accessTokenExpire";

const Signup = () => {
  const navigate = useNavigate();
  const {handleError} = useCustomError()
  const [isEmailAvailable, setIsEmailAvailable] = useState<{
    email: string;
    isAvailable: boolean;
  } | null>();
  const refferal_Code = sessionStorage.getItem("refferalCode");
  const [userNameValidation, setUserNameValidation] = useState<number | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [emailValidation, setEmailValidation] = useState<number | null>(null);
  const { handleSignupAsync, status } = useSignupAuth();
  const validationSchema = Yup.object({
    username: Yup.string()
      .matches(/^\S*$/, "Spaces are not allowed in the username")
      .matches(
        /^[a-zA-Z][a-zA-Z0-9~!@$&_\-&]*$/,
        "Username must start with a letter and can contain numbers, letters, ~, !, @, $, _, -, or &"
      )
      .min(6, "Username must be at least 6 characters")
      .max(20, "Username must not exceed 20 characters")
      .required("Username is required"),
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      )
      .max(50, "Email must be at most 50 characters")
      .email("Enter valid Email")
      .required("Email is required"),
  });
  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      referralCode: refferal_Code || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (value, { resetForm }) => {
      await handleSignupAsync(value);
      resetForm();
    },
  });

  const handleUserValidation = useCallback(
    debounce(async (username: string) => {
      if (username.length < 6) {
        return;
      }
      try {
        const res = await GET_USER_VALIDATION(
          serialize({
            user_name: username,
          })
        );

        if (username === "") {
          setUserNameValidation(null);
        } else if (res.data.success) {
          setUserNameValidation(200);
        }
      } catch (error: any) {
        if (error.response.status === 409) {
          setUserNameValidation(error.response.status);
        }
        else{
          handleError(error)
        }
      }
    }, 500),
    []
  );

  const checkEmail = async (email: string) => {
    try {
      setIsLoading(true)
      const res = await GET_EMAIL_VALIDATION(
        serialize({
          email: email,
        })
      );
      if (email === "") {
        setEmailValidation(null);
        setIsEmailAvailable(null);
      } else if (res.data.success) {
        setEmailValidation(200);
        setIsEmailAvailable({
          email: formik.values.email,
          isAvailable: true,
        });
      }
    } catch (error: any) {
      if (error.response.status === 409) {
        setEmailValidation(error.response.status);
      }
      else{
        handleError(error)
      }
    }
    finally{
      setIsLoading(false)
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex justify-center items-center text-center">
          <Typography
            variant="h2"
            isIcon={false}
            className="text-text-primary mobile:text-xl mobile:leading-[32px]"
          >
            BLACK PASS SIGN UP
          </Typography>
        </div>
        <div className="py-3 mobile:py-3">
          <img src={Images.LINE} alt="line" />
        </div>
        <div className="flex flex-col gap-3 mobile:gap-2">
          <Typography isIcon={true} font="regular" variant="p">
            Choose Username
          </Typography>
          <div className="w-full relative ">
            <Textfield
              placeHolder="Choose Username"
              name="username"
              value={formik.values.username}
              onChange={(e: any) => {
                formik.handleChange(e);
                handleUserValidation(e.target.value);
              }}
              onBlur={formik.handleBlur}
              className={`${
                formik.touched.username && formik.errors.username
                  ? "!border-red-600"
                  : "border-none"
              }`}
            />
            <div className="absolute top-0 right-2 bottom-0 flex justify-center items-center text-light-gray-200">
              {userNameValidation &&
              userNameValidation === 200 &&
              !formik.errors.username ? (
                <RightLogo />
              ) : userNameValidation === 409 &&
                formik.values.username !== "" ? (
                <WrongLogo />
              ) : (
                ""
              )}
            </div>
          </div>
          {formik.touched.username && formik.errors.username && (
            <Typography
              isIcon={false}
              variant="p"
              font="regular"
              className="!text-red-600"
            >
              {formik.errors.username}
            </Typography>
          )}
          <Typography isIcon={true} font="regular" variant="p">
            Email
          </Typography>
          <div className="w-full relative ">
            <Textfield
              placeHolder="Email"
              name="email"
              value={formik.values.email}
              // onChange={formik.handleChange}
              onChange={(e: any) => {
                formik.handleChange(e);
                if(isEmailAvailable && (e.target.value !== isEmailAvailable?.email)){
                  setIsEmailAvailable({
                    ...isEmailAvailable,
                    isAvailable: false
                  })
                }
                else if(isEmailAvailable && (e.target.value === isEmailAvailable?.email)){
                  setIsEmailAvailable({
                    ...isEmailAvailable,
                    isAvailable: true
                  })
                }
              }}
              onBlur={formik.handleBlur}
              className={`${
                formik.touched.email && formik.errors.email
                  ? "!border-red-600"
                  : "border-none"
              } pr-16`}
            />
            <div className="absolute top-0 right-2 bottom-0 flex justify-center items-center text-light-gray-200">
              {((isEmailAvailable?.email === formik.values.email ||
                isEmailAvailable?.email !== formik.values.email) &&
                emailValidation === 409) ||
              (isEmailAvailable?.email !== formik.values.email &&
                emailValidation === 200) || !isEmailAvailable ? (
                <button
                  type="button"
                  onClick={() => checkEmail(formik.values.email)}
                  className="px-2 text-text-secondary underline cursor-pointer disabled:cursor-not-allowed"
                  disabled={
                    status?.isLoading || formik.values.email ? false : true || isLoading
                  }
                >Verify</button>
              ) : null}
              {isEmailAvailable?.email === formik.values.email &&
              emailValidation &&
              emailValidation === 200 &&
              !formik.errors.email ? (
                <RightLogo />
              ) : emailValidation === 409 && formik.values.email !== "" ? (
                null
              ) : (
                ""
              )}
            </div>
          </div>
          {formik.touched.email && formik.errors.email && (
            <Typography
              isIcon={false}
              variant="p"
              font="regular"
              className="!text-red-600"
            >
              {formik.errors.email}
            </Typography>
          )}
          {emailValidation === 409 && formik.values.email !== "" ? <Typography
              isIcon={false}
              variant="p"
              font="regular"
              className="!text-red-600"
            >
              Email already registered. Try another
            </Typography> : null}
          <Typography isIcon={true} font="regular" variant="p">
            Referral (Optional)
          </Typography>
          <div className="w-full relative ">
            <Textfield
              className="cursor-default"
              name="referralCode"
              value={formik.values.referralCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeHolder="Enter Referral Code"
            />
          </div>

          <div className="group flex flex-col justify-center items-center mt-3 gap-2">
            <Button
              bgColor={true}
              CLASSNAME="px-2 text-text-primary group-hover:text-text-secondary"
              type="submit"
              size="extraSmall"
              color="white"
              isBorderLabel="SEND LINK"
              isBorder={true}
              //   isBorderLoading={signUpResponse.status === "pending"}
              disable={status.isLoading || !isEmailAvailable || !isEmailAvailable?.isAvailable || emailValidation === 409 || userNameValidation === 409 || isLoading}
            />
            <Typography
              isIcon={false}
              className="text-text-primary flex flex-row items-center gap-1 mobile:flex-col mobile:gap-0"
            >
              Already have an account?{" "}
              <Typography
                onClick={() => navigate("/sign-in")}
                isIcon={false}
                className="text-text-secondary cursor-pointer"
              >
                Sign In
              </Typography>
            </Typography>
          </div>
        </div>
      </form>
    </>
  );
};

export default Signup;
