import {
  DisclaimerComponent,
  RainbowKitProvider,
  getDefaultConfig,
} from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import {
  coinbaseWallet,
  gateWallet,
  metaMaskWallet,
  rainbowWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import MyProfile from "components/EditProfile";
import Maintenance from "components/Maintenance";
import {
  APP_NAME,
  ENV,
  MAINTENANCE_MODE,
  RAINBOW_PROJECT_ID,
} from "constants/config";
import { isMobileDevice } from "constants/utils";
import { UserProvider, useUser } from "context/userContext";
import AccountRecovery from "pages/Auth/AccountRecovery";
import AuthLayout from "pages/Auth/AuthLayout";
import ChangePassword from "pages/Auth/ChangePassword";
import ForgotPassword from "pages/Auth/ForgotPassword";
import ResetPassword from "pages/Auth/ResetPassword";
import SetPassword from "pages/Auth/SetPassword";
import Signin from "pages/Auth/Signin";
import Signup from "pages/Auth/Signup";
import { SignUp } from "pages/Authentication/SignUp";
import { Dashboard } from "pages/Dashboard";
import { DevientWallets } from "pages/Dashboard/DevientWallets";
import { Faq } from "pages/Faq";
import { Leaderboard } from "pages/LeaderBoard";
import { Referrals } from "pages/Referrals";
import RootLayout from "pages/RootLayout";
import { SuperNova } from "pages/SuperNova";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  base,
  baseSepolia,
  mainnet,
  polygon,
  polygonZkEvmCardona,
} from "viem/chains";
import { WagmiProvider } from "wagmi";
import "../../../src/App.css";
import AuthRoot from "./Authroot";
import PrivateRoute from "./PrivateRoute";
// import { boba } from 'wagmi/chains';
function App() {
  const queryClient = new QueryClient();

  const bobaBnbMainnet = {
    id: 56288,
    name: "Boba BNB Mainnet",
    network: "boba-bnb-mainnet",
    nativeCurrency: {
      decimals: 18,
      name: "BOBA",
      symbol: "BOBA",
    },
    rpcUrls: {
      default: {
        http: ["https://bnb.boba.network"],
        webSocket: ["wss://bnb.boba.network/ws"],
      },
    },
    blockExplorers: {
      default: {
        name: "BobaScan",
        url: "https://bnb.bobascan.com",
      },
    },
  };

  const bobaBnbTestnet = {
    id: 9728,
    name: "Boba BNB Testnet",
    network: "boba-bnb-testnet",
    nativeCurrency: {
      decimals: 18,
      name: "BOBA",
      symbol: "BOBA",
    },
    rpcUrls: {
      default: {
        http: ["https://boba-bnb-testnet.gateway.tenderly.co"],
      },
    },
    blockExplorers: {
      default: {
        name: "BobaScan Testnet",
        url: "https://testnet.bnb.bobascan.com",
      },
    },
  };

  const bobaNetwork = ENV === "PROD" ? bobaBnbMainnet : bobaBnbTestnet;

  const polygonChain = ENV === "PROD" ? polygon : polygonZkEvmCardona;

  const baseChain = ENV === "PROD" ? base : baseSepolia;

  coinbaseWallet.preference = "smartWalletOnly";
  
  const config = getDefaultConfig({
    appName: APP_NAME,
    projectId: RAINBOW_PROJECT_ID || "",
    chains: [mainnet, polygonChain, bobaNetwork, baseChain],

    wallets: [
      {
        groupName: "Popular",
        wallets: [
          gateWallet,
          metaMaskWallet,
          coinbaseWallet,
          rainbowWallet,
          walletConnectWallet,
        ],
      },
    ],
  });

  const Disclaimer: DisclaimerComponent = ({ Text, Link }) => (
    <Text>
      {isMobileDevice() && (
        <div className="text-red-700 text-base">
          Note: Please use Wallet Browser for an enhanced user experience on
          mobile devices.
        </div>
      )}
    </Text>
  );

  return (
    <>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider
            modalSize="compact"
            appInfo={{ disclaimer: Disclaimer }}
          >
            <UserProvider>
              <RouterOutlet />
            </UserProvider>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>

      <ToastContainer
        pauseOnFocusLoss={false}
        theme="dark"
        style={{ zIndex: 999999 }}
        autoClose={3000}
      />
    </>
  );
}

export default App;

console.log("Maintenance Mode::", MAINTENANCE_MODE);

const RouterOutlet = () => {
  const { configData } = useUser();

  return (MAINTENANCE_MODE?.toLowerCase() === 'true' ? true : false || configData?.is_maintainance_mode) ? (
    <Router>
      <Routes>
        <Route path="/" element={<Maintenance />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  ) : (
    <Router>
      <Routes>
        <Route path="/" element={<RootLayout />}>
          <Route element={<AuthRoot />}>
            {/* <Route index element={<LandingPage />} /> */}
            {/* <Route index element={<Home />} /> */}
            <Route path="/faq" element={<Faq />} />
            {/* <Route path="/faction" element={<Fraction/>} /> */}
            {/* <Route path="/signup" element={<PrivateRoute Component={SignUp} />} /> */}
            <Route path="/signup" element={<SignUp />} />
            {/* <Route path="/verify" element={<PrivateRoute Component={Verify} />} /> */}
            <Route
              path="/dashboard"
              element={<PrivateRoute Component={Dashboard} />}
            />
            <Route
              path="/leaderboard"
              element={<PrivateRoute Component={Leaderboard} />}
            />
            <Route
              path="/deviants"
              element={<PrivateRoute Component={DevientWallets} />}
            />
            <Route
              path="/referrals"
              element={<PrivateRoute Component={Referrals} />}
            />
            <Route
              path="/profile"
              element={<PrivateRoute Component={MyProfile} />}
            />
            <Route
              path="/supernova"
              element={<PrivateRoute Component={SuperNova} />}
            />
            <Route
              path="/profile/change-password"
              element={<PrivateRoute Component={ChangePassword} />}
            />
            <Route
              path="/sign-up"
              element={<AuthLayout Component={Signup} />}
            />
            <Route
              path="/sign-in"
              element={<AuthLayout Component={Signin} />}
            />
            <Route
              path="/forgot-password"
              element={<AuthLayout Component={ForgotPassword} />}
            />
            <Route
              path="/reset-password"
              element={<AuthLayout Component={ResetPassword} />}
            />
            <Route
              path="/set-password"
              element={<AuthLayout Component={SetPassword} />}
            />
            <Route
              path="/account-recovery"
              element={<AuthLayout Component={AccountRecovery} />}
            />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};
